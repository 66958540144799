import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const PostLink = ({ post }) => {
  const readingTime = `Reading time: ${post.timeToRead} Min.`;
  const { frontmatter: { path, mainImage, category, title, date } } = post;
  const pathname = typeof window !== 'undefined' && window.location.pathname;

  return (
    <div className="post-link">
      <Link to={path} className="post-link__img" tabIndex="-1" state={{ prevPath: pathname }}>
        <div className="label">{path}</div>
        <Img fluid={mainImage.childImageSharp.fluid} alt={title} title={title} />
      </Link>

      <div className="post-link__text-content">
        {category && (
          <Link className="post-link__category" to={`/blog/category/${category}`}>
            <div className="label">{category}</div>
            {category}
          </Link>
        )}

        <Link to={path} state={{ prevPath: pathname }}>
          <h3 className="post-link__title">
            {title}
          </h3>
        </Link>

        <div className="post-link__info-text">
          <div tabIndex="0">
            <i className="fa fa-calendar" />
            {date}
          </div>
          <div tabIndex="0">
            <i className="fa fa-clock-o" />
            {readingTime}
          </div>
        </div>

        <Link to={path} className="post-link__excerpt" state={{ prevPath: pathname }}>
          {post.excerpt}
        </Link>
      </div>
    </div>
  );
};

PostLink.propTypes = {
  post: PropTypes.shape({}).isRequired,
};

export default PostLink;
