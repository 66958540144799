import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PostLinkPopular from './PostLinkPopular';

class PopularPosts extends Component {
  constructor(props) {
    super(props);

    const { posts } = props;

    const popular = posts
      .filter(edge => edge.node.frontmatter.popular)
      .map(edge => <PostLinkPopular key={edge.node.id} post={edge.node} />);

    this.state = {
      popular,
    };
  }

  render() {
    const { popular } = this.state;

    return (
      <div className="popular-posts">
        <div className="popular-posts__header">
          <span className="popular-posts__title" tabIndex="0">Popular</span>
        </div>

        <div className="popular-posts__list">
          {popular}
        </div>
      </div>
    );
  }
}

PopularPosts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PopularPosts;
