import React from 'react';
import PropTypes from 'prop-types';

const Tags = ({ all, onTagClick, onResetClick }) => {
  const tagsToShow = Object.keys(all).map((key) => {
    const tag = all[key];

    return (
      <div
        className={`tags__item ${tag.active ? 'tags__active' : ''}`}
        key={tag.name}
        onClick={() => onTagClick(key)}
        tabIndex="0"
        onKeyDown={(event) => { if (event.keyCode === 13) onTagClick(key); }}
      >
        <span className="tags__name">
          {tag.name}
        </span>
      </div>
    );
  });

  return (
    <div className="tags">
      <div className="tags__header">
        <span className="tags__title" tabIndex="0">Tags</span>
        <div
          className="tags__reset"
          onClick={() => onResetClick()}
          tabIndex="0"
          onKeyDown={(event) => { if (event.keyCode === 13) onResetClick(); }}
        >
          <i className="fa fa-refresh" />
          <span>Reset</span>
        </div>
      </div>

      <div className="tags__list">
        {tagsToShow}
      </div>
    </div>
  );
};

Tags.propTypes = {
  all: PropTypes.shape({}).isRequired,
  onTagClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
};

export default Tags;
