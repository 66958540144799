import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const PostLinkPopular = ({ post: { frontmatter: { path, mainImage, title, date } } }) => (
  <div className="post-link-popular">
    <Link className="post-link-popular__img" to={path} tabIndex="-1">
      <div className="label">{path}</div>
      <Img fluid={mainImage.childImageSharp.fluid} alt={title} title={title} />
    </Link>

    <div className="post-link-popular__text-content">
      <Link to={path}>
        <h3 className="post-link-popular__title">{title}</h3>
      </Link>

      <div className="post-link-popular__date-layout" tabIndex="0">
        <div className="icon fa-calendar" />
        <span>{date}</span>
      </div>
    </div>
  </div>
);

PostLinkPopular.propTypes = {
  post: PropTypes.shape({}).isRequired,
};

export default PostLinkPopular;
