import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Search extends Component {
  handleOnIconClick = () => {
    const { value, onClearIconClick } = this.props;

    if (value) {
      onClearIconClick();
    }
  };

  render() {
    const { onChange, placeholder, value } = this.props;

    const iconClass = value ? 'fa-times search__clickable' : 'fa-search';

    return (
      <div className="search">
        <label htmlFor="search" className="label">search</label>
        <input
          className="search__input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          id="search"
        />

        <i
          className={`fa ${iconClass} search__icon`}
          onMouseDown={this.handleOnIconClick}
        />
      </div>
    );
  }
}

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClearIconClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

Search.defaultProps = {
  placeholder: 'Search',
  value: '',
};

export default Search;
