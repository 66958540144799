import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { each, get, uniq } from 'lodash';

import Loadable from 'react-loadable';
import Layout from '../components/GatsbyLayout';

import Button from '../components/Button';
import SearchEmpty from '../assets/images/blog/search-empty-state.svg';
import BlogBG from '../assets/images/blog/blog-banner.png';
import Pagination from '../components/Pagination';
import PopularPosts from '../components/PopularPosts';
import PostLink from '../components/PostLink';
import Search from '../components/Search';
import Tags from '../components/Tags';

import { arrayIncludesAnotherArray, arrayIncludesSubstring, xor } from '../util/arrays';
import { stringIncludesSubstring } from '../util/string';
import detectWebCrawler from '../util/web-crawler';
import { getPathSegmentAfterCertainOne } from '../util/url';
import Subtitle from '../components/Subsection/Subtitle';
import CTA from '../components/CTA';


const Loading = () => <h3>Loading...</h3>;

const FooterComponent = Loadable({
  loader: () => import('../components/Footer'),
  loading: Loading
});

const numberOfPostsPerPageUpdate = 6;
// The breakpoints where the bottom limit for infinite pagination will increase
const mediumBreakpoint = 1106;
const smallBreakpoint = 480;

class BlogList extends Component {
  static getAllUniqueTags = (posts) => {
    let tags = [];

    each(posts, (edge) => {
      if (get(edge, 'node.frontmatter.tags')) {
        tags = tags.concat(edge.node.frontmatter.tags);
      }
    });

    return uniq(tags);
  };

  static shouldRenderPost(edge, searchTerm, activeTags, activeCategory) {
    const { node: { frontmatter: { title, description, tags, category } } } = edge;

    const nameFoundInTitle = stringIncludesSubstring(searchTerm, title);
    const nameFoundInDescription = stringIncludesSubstring(searchTerm, description);
    const nameFoundInTags = arrayIncludesSubstring(searchTerm, tags);

    const matchesCategory = activeCategory ? stringIncludesSubstring(category, activeCategory) : true;

    const searchTermFound = nameFoundInTitle || nameFoundInDescription || nameFoundInTags;
    const tagsFound = arrayIncludesAnotherArray(activeTags, tags);

    return tagsFound && searchTermFound && matchesCategory && !!edge.node.frontmatter.date;
  }

  static renderCategory(category) {
    const categoryParsed = category.replace(/\//g, '');

    return (
      <div className="blog-list__category">
        <h3 className="blog-list__category--selected">{categoryParsed}</h3>
        <Button
          type="secondary"
          goTo="/blog/"
          title="View All"
          internalLink
        />
      </div>
    );
  }

  static renderHelmet = () => {
    let siteURL = 'https://mcro.tech';
    const title = 'Our Blog - Learn From Our Thought Leadership | MCRO'

    if (typeof window !== 'undefined' && window.location) {
      siteURL = window.location.origin;
    }

    return (
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={BlogBG} />
        <meta property="og:url" content={`${siteURL}/blog/`} />
        <meta property="og:image:secure_url" content={BlogBG} />
        <meta
          name="description"
          content="Stay up to date with the latest technology trends and shares knowledge with the community.
        We deliver innovation and technology content."
        />
        <meta
          name="keywords"
          content="Tech Content, Articles, React, React Native, Angular, Ionic, iOS, Android, NodeJS, PHP, AWS,
              Devops, Security, QA, Automation, Blockchain, Product, Digital Innovation, Best Mobile Developers,
              Best Cross Platform Developers, Best Product Thinkers, Best Managing Consultants"
        />
        <meta name="twitter:image" content={BlogBG} />
        <link rel="canonical" href={`${siteURL}/blog/`} />
      </Helmet>
    );
  }

  constructor(props) {
    super(props);

    const { data: { all, paginated } } = this.props;
    const allTags = BlogList.getAllUniqueTags(all.edges);

    const category = getPathSegmentAfterCertainOne('category');
    const crawler = detectWebCrawler();
    const posts = crawler ? paginated.edges : all.edges;
    const tagsParsed = {};

    allTags.forEach((tag) => {
      tagsParsed[tag] = {
        active: false,
        name: tag,
      };
    });

    this.state = {
      activeTags: [],
      category,
      crawler,
      numberOfPostsToShow: numberOfPostsPerPageUpdate,
      posts,
      searchTerm: '',
      showMore: false,
      tags: tagsParsed,
      ticking: false,
    };
  }

  componentDidMount() {
    const { category } = this.state;

    if (localStorage.getItem('blog')) {
      localStorage.setItem('blog', '');
      const offset = localStorage.getItem('scrollOffset');
      if (offset && !category) {
        this.setState({ numberOfPostsToShow: Number(localStorage.getItem('noOfPosts')) });
        setTimeout(() => {
          window.scroll({ top: Number(offset), left: 0, behavior: 'smooth' });
        });
      }
    }

    localStorage.setItem('scrollOffset', '');
    localStorage.setItem('noOfPosts', '');

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const { numberOfPostsToShow } = this.state;

    localStorage.setItem('scrollOffset', window.pageYOffset.toString());
    localStorage.setItem('noOfPosts', numberOfPostsToShow);

    window.removeEventListener('scroll', this.handleScroll);
  }

  handleSearchInputChange = (event) => {
    const { target: { value } } = event;

    this.setState({ searchTerm: value });
  };

  clearSearchText = () => {
    this.setState({ searchTerm: '' });
  };

  handleScroll = () => {
    const { ticking } = this.state;

    if (!ticking) {
      this.setState({ ticking: true });

      requestAnimationFrame(() => this.updateInfinitePagination());
    }
  };

  onTagClick = (key) => {
    const { tags, activeTags } = this.state;

    this.setState({
      tags: {
        ...tags,
        [key]: {
          ...tags[key],
          active: !tags[key].active
        }
      },
      activeTags: xor(activeTags, key),
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  onTagResetClick = () => {
    const { tags } = this.state;
    const tagsToReturn = {};

    Object.keys(tags).forEach((key) => {
      tagsToReturn[key] = {
        active: false,
        name: key,
      };
    });

    this.setState({
      activeTags: [],
      tags: tagsToReturn
    });
  };

  loadMorePosts = () => {
    const { numberOfPostsToShow } = this.state;

    this.setState({
      numberOfPostsToShow: numberOfPostsToShow + numberOfPostsPerPageUpdate,
      showMore: true,
    });
  };

  updateInfinitePagination() {
    const { showMore, numberOfPostsToShow } = this.state;

    const distanceToBottom = document.documentElement.offsetHeight - (window.scrollY + window.innerHeight);
    const screenWidth = window.innerWidth;

    let distanceToBottomLimit = 800;

    const screenSizeBetweenMediumAndSmall = screenWidth < mediumBreakpoint && screenWidth >= smallBreakpoint;
    const screenSizeSmall = screenWidth < smallBreakpoint;

    if (screenSizeBetweenMediumAndSmall) {
      distanceToBottomLimit = 800;
    } else if (screenSizeSmall) {
      distanceToBottomLimit = 1250;
    }

    const scrollArrivedToBottom = distanceToBottom < distanceToBottomLimit;

    if (showMore && scrollArrivedToBottom) {
      this.setState({ numberOfPostsToShow: numberOfPostsToShow + numberOfPostsPerPageUpdate });
    }

    this.setState({ ticking: false });
  }

  renderTags() {
    const { tags } = this.state;

    return (
      <Tags
        all={tags}
        onTagClick={this.onTagClick}
        onResetClick={this.onTagResetClick}
      />
    );
  }

  renderClassicPagination() {
    const { pageContext: { currentPage, numPages } } = this.props;

    return (
      <Pagination
        currentPage={currentPage}
        numPages={numPages}
        path="blog"
      />
    );
  }

  renderSearchInput() {
    const { searchTerm } = this.state;

    return (
      <Search
        onChange={this.handleSearchInputChange}
        onClearIconClick={this.clearSearchText}
        value={searchTerm}
      />
    );
  }

  renderNoResults() {
    const { searchTerm } = this.state;

    const forSearchTerm = searchTerm && `"for ${searchTerm}"`;

    const description = `Your search ${forSearchTerm} didn't return any results.`;

    return (
      <div className="blog-list__no-results align-center">
        <img src={SearchEmpty} alt="No results" title="No results" />
        <div className="blog-list__no-results--title">Oops!</div>
        <div className="blog-list__no-results--description">{description}</div>
      </div>
    );
  }

  renderMorePosts = (showLoadMoreButton, windowSize) => {
    switch (true) {
      case windowSize <= 1024:
        return showLoadMoreButton && typeof window !== 'undefined'
          && window.pageYOffset >= 960 && this.loadMorePosts();
      case windowSize < 1450:
        return showLoadMoreButton && typeof window !== 'undefined'
          && window.pageYOffset >= 1200 && this.loadMorePosts();
      case windowSize < 1600:
        return showLoadMoreButton && typeof window !== 'undefined'
          && window.pageYOffset >= 800 && this.loadMorePosts();
      default:
        return showLoadMoreButton && typeof window !== 'undefined'
          && window.pageYOffset >= 600 && this.loadMorePosts();
    }
  };

  render() {
    const { location } = this.props;
    const { searchTerm, showMore, numberOfPostsToShow, posts, crawler, activeTags, category } = this.state;

    const FilteredPosts = posts
      .filter(edge => BlogList.shouldRenderPost(edge, searchTerm, activeTags, category))
      .map(edge => <PostLink key={edge.node.id} post={edge.node} />);

    const PostsToRender = FilteredPosts.slice(0, numberOfPostsToShow);

    const showLoadMoreButton = FilteredPosts.length > PostsToRender.length && !showMore;

    return (
      <Layout location={location}>
        {BlogList.renderHelmet()}
        <div className="main blog-list">
          {/* This banner will be changed
          <img className="main__banner-image" src={BlogBG} alt="" />
          */}
          <div className="main__inner">
            <div className="blog-list__header">
              <Subtitle
                titleType="withDotH1"
                withoutLine
                title="Our Blog"
                classNames="force-center margin-top-medium"
              />
              <h2 className="blog-list__header__subtitle" tabIndex="0">Industry insights from MCRO’s experts.</h2>
            </div>
            <div className="main__section blog-list__section">
              {!crawler && (
                <div className="blog-list__filters">
                  {this.renderSearchInput()}
                  {!category && !location.pathname.includes('/category/') && this.renderTags()}
                  {<PopularPosts posts={posts} />}
                </div>
              )}

              <div className="blog-list__posts">
                {category && BlogList.renderCategory(category)}

                {PostsToRender.length ? PostsToRender : this.renderNoResults()}

                {this.renderMorePosts(showLoadMoreButton, typeof window !== 'undefined' && window.innerWidth)}
              </div>
            </div>

            {crawler && this.renderClassicPagination()}
          </div>
        </div>
        {FilteredPosts.length > PostsToRender.length || location.pathname.includes('/blog/category/') ? null : (
          <div id="wrapper-footer">
            <div className="footer">
              <CTA />
              <FooterComponent />
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

BlogList.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
};

export default BlogList;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    paginated: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            date(formatString: "MMMM DD, YYYY")
            path
             mainImage {
                childImageSharp{
                    fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    },
    all: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            category
            popular
            date(formatString: "MMMM DD, YYYY")
            path
             mainImage {
                childImageSharp{
                    fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
  }
`;
